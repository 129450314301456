import React from 'react'
import { graphql, PageProps } from 'gatsby'
// Components
import Layout from 'components/Layout'
import ProfileContainer from 'components/Sections/Profile/ProfileContainer'
import PageTitle from 'components/UIElements/PageTitle'
import RichText from 'components/UIElements/RichText'
import Blockquote from 'components/UIElements/Blockquote'
// Models
import { IProfileHeader, IProfileMenuItem } from 'models/Profile'
import { IOptionalMetaProps } from 'models/SEO'
import { IArtist } from 'models/Artist'

type ArtistPageProps = {
    artist: IArtist
    songs: {
        totalCount: number
    }
    discography: {
        totalCount: number
    }
}

type Context = {
    slug: string
}

const ArtistPage: React.FC<PageProps<ArtistPageProps, Context>> = ({
    data,
}) => {
    const artist = data.artist
    const totalSongs = data.songs.totalCount
    const totalDisks = data.discography.totalCount
    const seo: IOptionalMetaProps = {
        title: artist.name,
        description: `Perfil de ${artist.name}`,
        image: artist.avatar.file?.url,
    }

    const { name, avatar, cover, slug } = artist
    const {
        amazonMusic,
        instagram,
        line,
        spotify,
        tiktok,
        twitter,
        web,
        youtube,
        youtubeMusic,
    } = artist
    const subname = artist.alias ? (
        <>
            Otros nombres:{' '}
            {artist.alias.map((a, index) => (
                <small key={a}>
                    {index + 1 < artist.alias!.length ? `${a}, ` : a}
                </small>
            ))}
        </>
    ) : (
        <></>
    )

    const headerProps: IProfileHeader = {
        type: 'Artista',
        name,
        avatar,
        cover,
        subname,
        social: {
            amazonMusic,
            instagram,
            line,
            spotify,
            tiktok,
            twitter,
            web,
            youtube,
            youtubeMusic,
        },
    }

    const menu: IProfileMenuItem[] = [
        { text: `Perfil`, to: `/artists/${slug}` },
        {
            text: `Discografía`,
            to: `/artists/${slug}/discography`,
            count: totalDisks,
        },
        { text: `Canciones`, to: `/artists/${slug}/songs`, count: totalSongs },
    ]

    const { bio } = artist

    return (
        <Layout seo={seo}>
            <ProfileContainer menu={menu} {...headerProps}>
                <PageTitle title="Información" />
                {bio ? (
                    <RichText content={bio} />
                ) : (
                    <Blockquote>
                        Aun no hemos escrito información sobre {artist.name}.
                    </Blockquote>
                )}
            </ProfileContainer>
        </Layout>
    )
}

export default ArtistPage

export const pageQuery = graphql`
    query ArtistPage($slug: String!) {
        artist: contentfulArtists(slug: { eq: $slug }) {
            name
            name_jap
            slug
            birthday
            alias

            avatar {
                gatsbyImageData(width: 200)
                file {
                    url
                }
            }
            cover {
                gatsbyImageData(layout: CONSTRAINED)
            }

            bio {
                raw
            }

            instagram
            youtube
            twitter
            line
            tiktok
            web
            spotify
            amazonMusic
            youtubeMusic
            deezer

            disks {
                name
                slug
                cover {
                    gatsbyImageData(layout: CONSTRAINED)
                }
            }
        }

        songs: allContentfulSongs(
            filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
        ) {
            totalCount
        }

        discography: allContentfulDisks(
            filter: { authors: { elemMatch: { slug: { eq: $slug } } } }
        ) {
            totalCount
        }
    }
`
